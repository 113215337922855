.image-input-label {
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
}

.image-input {
    display: none;
}

div .img-signup {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.image-input-label p {
    color: #6F6C90;
    font-family: Cairo;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;


}