.card-of-about {
  border-radius: 16px;
  box-shadow: 0px 0px 24px 0px #39C0C033;
  overflow: hidden;
  width: 338.41px;
  height: 401.75px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.topping {
  flex-direction: column;
}

.card-of-about:hover {
  animation: dissolve 200ms ease-out;
  -webkit-animation: dissolve 200ms ease-out;
  transition: .2s;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -ms-transition: .2s;
  -o-transition: .2s;
}

.card-of-about {}

.card-of-about img {
  bottom: -30%;
}

.card-of-about p {
  text-align: justify;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  text-align: center;
  width: 265.12px;
  height: 76.91px;
  margin: 0 20px;
}


@keyframes dissolve {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width:400px) {
  .topping {
    width: 80%;
    flex-direction: row;
  }

  .topping img {
    width: 50px;
  }

  .card-of-about {
    justify-content: start;
    border-radius: 0px !important;
    height: 200px;
    width: 100%;
  }
}