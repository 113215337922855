.carding h4{
    font-family: Cairo;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
color:#ffffff;
margin: 5px;
}
.carding p {
    font-family: Cairo;
font-size: 14px;
font-weight: 500;
line-height: 21px;
text-align: center;
color:#ffffff;
margin:5px
}