.swipers {
    height: 90vh;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.swiper-button-prev::after,
.swiper-rtl .swiper-button-next::after {
    color: black;
    position: absolute;
    left: -10px;
}

@media (max-width:400px) {
    .swipers {
        height: auto;
    }
    .swiper-button-prev::after
 {
    color: black;
    position: absolute;
    left: 10px;
}
}