.top-home {
    height: 100vh;
    background: linear-gradient(181.36deg, rgba(19, 163, 217, 0.4) 1.16%, rgba(19, 163, 217, 0.4) 1.17%, rgba(20, 188, 251, 0.4) 109.5%), url('../../../assets/images/2fa2bbdb9775f78be089cbd5433dfb6b.gif');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.bg-home {
    position: absolute;
    z-index: -1;
}

.rectan {
    position: absolute;
    width: 258px !important;
    height: 928px;
    gap: 0px;
    opacity: 0px;
    right: 7%;
    z-index: 1;
    top: -60%;
    background: #14BCFB;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.text-top {
    position: absolute;
    right: 9.5%;
    top: 25%;
    z-index: 3;
}

.text-top h3 {
    font-family: Cairo;
    font-size: 53px;
    font-weight: 400;
    line-height: 71px;
    text-align: center;
    color: white;
    margin-bottom: 30px;

}

.text-top h3 span {
    font-family: Cairo;
    font-size: 53px;
    font-weight: 800;
    line-height: 71px;
    text-align: center;
}

.text-top p {
    font-family: Cairo;
    font-size: 18px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    color: white;
    width: 498px;
    height: 68px;
    padding: 0 35px;

}

.top-btn.btn {
    font-family: Cairo;
    font-size: 14px;
    font-weight: 700;
    line-height: 26.24px;
    text-align: center;
    border-radius: 52px;
    color: white;
    background-color: #13A3D9;
    width: 122px;
    height: 48px;
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    -ms-border-radius: 52px;
    -o-border-radius: 52px;
}

.top-btn.btn:hover {
    color: rgb(255, 255, 255);
    background-color: #0e85b1;
}

.foot-of-top {
    height: 20%;
    background-color: #13A3D9;
}

.foot-of-top .inside {
    width: 70%;
    flex-wrap: wrap;

}

@media (max-width:400px) {
    .text-top {
        position: absolute;
        top: 59%;
        right: 2.5%;
        z-index: 3;
        width: 100% !important;

    }

    .text-top h3,
    .text-top h3 span {
        font-family: Cairo;
        font-size: 24px;
        font-weight: 800;
        line-height: 48.72px;
        text-align: center;
        width: 100%
    }

    .text-top p {
        font-family: Cairo;
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        width: 100%
    }

    .rectan {
        position: absolute;
        width: 208px !important;
        height: 140%;
        gap: 0px;
        opacity: 0px;
        right: 7%;
        z-index: 1;
        top: -60%;
        background: #14BCFB;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }

    .inside {
        align-items: end;
    }

    .carding {
        flex-wrap: wrap-reverse;
        height: 80px;
        width: 110px !important;
    }

    .carding img {
        padding: 40px 0px 40px 0px;
    }
}