.About-ACIT {
    direction: rtl;
}
.About-ACIT  .title-partner h4.title-blue {
    color: #1A457B;
    font-family: Cairo;
    font-size: 24px;
    font-weight: 900;
    line-height: 41px;
    text-align: center;

}
