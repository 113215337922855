.user-container {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-container div h4 {
    /* اسم الطالب */
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    text-align: right;

    color: #000000;


}

.user-container a {
    /* تعديل الحساب */

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height, or 120% */
    text-align: right;
    text-decoration-line: underline;
    color: rgba(9, 95, 144, 0.51);
    margin: 10px 0px;
}

.button-url {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.user-container div p {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: right;
    color: #095F90;
    margin-bottom: 10px;
}

.user-container button {
    border-radius: 10px;
    /* #17 */


    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 10px;
    /* identical to box height */
    text-align: center;
    margin: 0px;
    color: #FFFFFF;
}

@media (max-width:400px) {
    .user-container {
        flex-direction: row;
    }

    .button-url {
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
    }

    .button-url button {
        width: 100% !important;
    }

    .button-url a {
        display: none;
    }
}