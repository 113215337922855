.partner{
    
    width:310px;
    height:60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.partner img{
    border-radius: 50%;
    box-shadow: 0px 4px 24px 0px #39C0C07A;
    width: 148px;
    height: 147px;
   
    background-color: #fff;
}

@media (max-width:400px) {
    .partner{
        height:40vh;
    }
    .partner img {
        border-radius: 50%;
        box-shadow: 0px 4px 24px 0px #39C0C07A;
        width: 80%;
        height: 80%;
        background-color: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
}
}
