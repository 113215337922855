h4.title-blue {
    font-family: Cairo;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #13A3D9;
    text-align: center;
    margin:0px 0px;
}

h2.title-black {
    font-family: Cairo;
    font-size: 42px;
    font-weight: 700;
    line-height: 63px;
    text-align: center;
    color: #0B325C;

}